@import "src/styles/variables.scss";

.source {
  .status-column {
    padding: 0.4rem 0.8rem;
    font-size: 1.4rem;
    border-radius: 0.4rem;
    display: inline-block;

    span {
      font-size: 1.4rem;
    }

    .ant-statistic {
      line-height: 0.8;
      height: 1.5rem;
    }
  }
  .income {
    background-color: $color-light-green;
    color: $color-green;
  }
  .outcome {
    background-color: $color-light-red;
    color: $color-red;
  }
  &-action {
    @include flex;
    gap: 2rem;
    .edit-icon {
      font-size: 2rem;
      color: $color-yellow;
      &:hover {
        color: $color-black !important;
      }
    }

    .delete-icon {
      width: 2rem;
      height: 2rem;
      fill: $color-red;
      &:hover {
        fill: $color-black !important;
      }
    }
  }
}
