@import "src/styles/variables";

.balance {
  color: $color-black;

  &-content {
    border-radius: 0.4rem;
    box-shadow: $shadow-card;
    overflow: hidden;
  }

  &-chart {
    border: none !important;
    border-radius: 0 !important;
  }
}

@media (max-width: 757px) {
  .balance {
    width: 100%;
    &-content {
      width: 100%;
    }
    &-chart {
      width: 100%;
      // overflow-y: auto;
      // height: 540px !important;
      // padding-top: 0 !important;
      @include scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 6px;
      }

      &-content {
        width: 70rem !important;
        min-height: 500px;
        position: relative;
        margin-bottom: -50px;
      }
    }
    &-chart-header {
      width: 70rem;
      width: 100%;
    }
  }
}
