@import "src/styles/variables";

.section-partners {
  padding-bottom: 10rem;
  background-color: #fff;
  position: relative;
  z-index: 5;

  .partners {
    &-wrap {
      h2 {
        letter-spacing: -0.01em;
        font-size: 48px;
        font-weight: 500;
        line-height: 72px;
        text-align: center;
        margin-bottom: 80px;

        span {
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
          -webkit-background-clip: text;
        }
      }
    }
    &-slide-card {
      height: 140px;
      @include flex-center;
      // max-width: 198px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 800px) {
  .section-partners {
    .partners {
      &-wrap {
        h2 {
          max-width: 500px;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 80px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .section-partners {
    padding-bottom: 80px;
    .partners {
      &-wrap {
        h2 {
          max-width: 100%;
          margin-bottom: 60px;
          font-size: 28px;
          line-height: 32px;
        }
      }
    }
  }
}
