@import "src/styles/variables";

.filter-date {
  background-color: $color-white !important;
  border-radius: 0.4rem;
}

.filter-source,
.filter-date {
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
  .ant-form-item {
    margin-bottom: 0;
  }
}
