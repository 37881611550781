@import "src/styles/variables.scss";

.storage {
  .ant-table {
    &-thead {
      tr {
        td,
        th {
          background-color: $color-grey-hover !important;
          border-color: $color_input_border !important;
        }
      }
    }
    &-tbody {
      tr {
        td {
          border-color: $color_input_border !important;
        }
        &[data-row-key="total"] {
          background-color: $color-table-blue !important;

          &:hover {
            td {
              background-color: $color-table-blue !important;
            }
          }
          td {
            font-weight: 600;
          }
        }
      }
    }
  }
}
