@import "src/styles/variables.scss";

.table {
  .ant-table-cell {
    text-align: center;
  }
}

.cashflow {
  .ant-table-tbody {
    .ant-table-row-level-0 {
      &[data-row-key="beginnig_balance"],
      &[data-row-key="beginnig_balance_pred"] {
        background-color: $color-light-green !important;
        &:hover {
          background-color: $color-light-green !important;
          .ant-table-cell {
            background-color: $color-light-green !important;

            &:nth-child(1),
            &:nth-child(2) {
              background-color: $color-table-blue !important;
            }
          }
        }
      }
      &[data-row-key="transfer"],
      &[data-row-key="Жорий пул оқимиchange_predМолиявий пул оқимиchange_predИнвестицион пул оқимиchange_pred"],
      &[data-row-key="Жорий пул оқимиchangeМолиявий пул оқимиchangeИнвестицион пул оқимиchange"] {
        background-color: $color-light-yellow !important;
        &:hover {
          background-color: $color-light-yellow !important;
          .ant-table-cell {
            background-color: $color-light-yellow !important;

            &:nth-child(1),
            &:nth-child(2) {
              background-color: $color-table-blue !important;
            }
          }
        }
      }
      &[data-row-key="ending_balance"],
      &[data-row-key="ending_balance_pred"] {
        background-color: $color-light-red !important;
        &:hover {
          background-color: $color-light-red !important;
          .ant-table-cell {
            background-color: $color-light-red !important;

            &:nth-child(1),
            &:nth-child(2) {
              background-color: $color-table-blue !important;
            }
          }
        }
      }
    }
  }
}
