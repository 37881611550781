@import "src/styles/variables";

.team-card {
  @include card;
  color: $color-black;
  width: 100%;
  @include flex;
  column-gap: 2rem;

  &-user {
    @include flex;
    column-gap: 1.6rem;
    width: 30%;

    &-icon {
      @include flex;
      justify-content: center;
      flex-shrink: 0;
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 0.4rem;
      background-color: $color-content-quaternary;

      svg {
        width: 3rem;
        height: 3rem;
      }
    }

    .ant-image {
      flex-shrink: 0;
    }

    h4 {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: $font-weight-normal;
      margin-bottom: 0.8rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-content-tertiary;
    }

    .ant-image-mask-info {
      display: none;
    }
  }

  &-telegram,
  &-phone,
  &-bot {
    @include flex;
    gap: 0.5rem;
    width: 20%;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $color-content-tertiary;
    transition: 0.3s;

    svg {
      transition: 0.3s;
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      color: $color-black !important;

      svg {
        transform: scale(1.1);
      }
    }
  }

  &-bot {
    width: 15rem;
  }
  .ant-ribbon {
    top: -2.6rem;
  }

  .telegram-icon,
  .phone-icon {
    flex-shrink: 0;
  }

  &-detail {
    @include flex;
    justify-content: flex-end;
    gap: 1rem;
    flex-grow: 1;

    .edit {
      background-color: $color-light-yellow;
      color: $color-yellow;
      &:hover {
        background-color: $color-yellow !important;
      }
    }

    .delete {
      background-color: $color-light-red;
      color: $color-red;
      &:hover {
        background-color: $color-red !important;
      }
    }

    &-btn {
      @include btn;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
    }
    .delete-icon {
      width: 1.6rem;
      height: 1.6rem;
      fill: currentColor;
    }
  }
}

@media (max-width: 1200px) {
  .team-card {
    flex-direction: column;
    row-gap: 1rem;
    width: calc(100% / 3 - 16px);
    align-items: flex-start;

    &-phone {
      margin-bottom: 2.5rem;
    }

    &-user {
      width: 100%;
      margin-bottom: 1rem;
    }

    &-detail {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 1010px) {
  .team-card {
    width: calc(50% - 12px);
  }
}

@media (max-width: 677px) {
  .team-card {
    width: 100%;
  }
}
