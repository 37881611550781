@import "src/styles/variables";

.edited {
  color: $color-black;

  h2 {
    font-family: $font-family-primary;
    font-size: 2.4rem;
    font-weight: $font-weight-secondary;
    margin-bottom: 3.2rem;
  }

  &-card {
    padding: 2.4rem;
    border-radius: 0.4rem;
    box-shadow: $shadow-card;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2.4rem;

    &:last-child {
      margin-bottom: 0;
    }

    &-delete {
      display: block;
      margin-left: auto;
      cursor: pointer;

      svg {
        fill: $color-red;
      }
    }

    &-icon {
      width: 5.6rem;
      height: 5.6rem;
      border: 1px solid $color-content-tertiary;
      align-self: center;
      border-radius: 50%;
      @include flex-center;
    }
    &-btn {
      @include btn;
      align-self: flex-end;
      background-color: $color-green;
      color: #fff;
      &:hover {
        background-color: $color-green-hover !important;
      }
    }
  }

  &-card-table {
    width: 100%;
    @include flex-space-between;
    border: 1px solid $color-content-tertiary;
    border-radius: 0.4rem;
    overflow-x: auto;
    @include scroll-x;

    div {
      padding: 2.2rem 0.8rem;
      border-right: 1px solid $color-content-tertiary;
      font-size: 1.6rem;
      text-align: center;
      // white-space: nowrap;
      height: 100%;
      flex-shrink: 0;

      &:nth-child(1) {
        width: 100px;
      }
      &:nth-child(2) {
        width: 100px;
        height: 63px;
      }
      &:nth-child(3) {
        width: 100px;
        height: 63px;
      }
      &:nth-child(4) {
        width: 80px;
      }
      &:nth-child(5) {
        width: 150px;
      }
      &:nth-child(6) {
        width: 150px;
      }
      &:nth-child(7) {
        width: 300px;
      }
      &:nth-child(8) {
        padding: 0;
        max-width: 300px;
        flex-grow: 1;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }
    }
    & > div:last-child {
      border-right: none;
    }
    .changed {
      color: $color-red;
    }
  }

  // &-cards {
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 2.4rem;
  // }
  .journal-change-table {
    .income-column,
    .outcome-column {
      .ant-statistic .ant-statistic-content {
        color: $color-red;
      }
    }
  }
}
