@import "src/styles/variables";

.transfercard {
  // width: 50rem;
  width: 100%;
  height: 20rem;
  background-color: #41a7df;
  border-radius: 0.8rem;
  border: 0.1rem solid $color_header_border;
  padding: 2.4rem;
  color: #fff;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &-header {
    font-size: 18px;
    font-family: $font-family-primary;
    font-weight: $font-weight-secondary;
  }

  &-footer {
    @include flex-space-between;

    .transfer-icon2,
    .transfer-icon1 {
      fill: #fff;
      flex-shrink: 0;
    }
  }

  &-price {
    p {
      font-size: 1.8rem;
      margin-bottom: 0.4rem;
    }

    h2 {
      font-size: 2.4rem;
      font-family: $font-family-primary;

      display: flex;
      align-items: center;
      column-gap: 4px;

      span {
        font-size: 2.4rem;
        font-family: $font-family-primary;
        color: #fff;
      }

      .ant-statistic,
      .ant-statistic-content {
        line-height: 1.4;
      }
    }
  }

  &-name {
    @include flex;
    gap: 0.4rem;
    align-self: flex-end;

    font-size: 1.6rem;
    font-family: $font-family-primary;
    font-weight: $font-weight-secondary;
  }
}

.transfercard-1 {
  background-color: #41a7df;
}
.transfercard-2 {
  background-color: #45cb85;
}

@media (max-width: 1245px) {
  .transfercard {
    height: 22rem;
    padding: 2rem;
    &-price {
      h2 {
        font-size: 2.8rem;
        font-family: $font-family-primary;
      }
    }
  }
}

@media (max-width: 450px) {
  .transfercard {
    &-price {
      p {
        font-size: 1.6rem;
      }
      h2 {
        font-size: 2.4rem;
      }
    }
    &-footer {
      .transfer-icon2,
      .transfer-icon1 {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
