@import "src/styles/variables";

.unConfirmed {
  color: $color-black;

  h2 {
    font-family: $font-family-primary;
    font-size: 2.4rem;
    font-weight: $font-weight-secondary;
    margin-bottom: 3.2rem;
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
  }

  &-card {
    @include card;
    width: calc(25% - 18px);
    transition: all 0.4s;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:hover {
      transform: translate(0, -5px);
    }

    &-header {
      @include flex;
      gap: 0.8rem;
      margin-bottom: 2.4rem;

      img {
        width: 4.8rem;
        object-fit: cover;
        border-radius: 50%;
      }
      h4,
      p {
        line-height: 2rem;
      }

      h4 {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 0.4rem;
      }
      p {
        font-size: 1.4rem;
        color: $color-content-tertiary;
      }
    }

    &-more {
      text-align: end;
    }

    &-info {
      flex-grow: 1;
    }

    &-delete {
      svg {
        fill: $color-red;
        cursor: pointer;
      }
    }

    &-main {
      h3 {
        @include flex;
        gap: 0.3rem;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1.6rem;

        span {
          font-size: 2rem;
          font-weight: 500;
          margin-bottom: 1.6rem;
        }
      }

      &-btn {
        margin-top: 0.6rem;
        font-size: 1.4rem;
        margin-left: auto;
        div {
          display: none;
        }
      }
    }

    &-text {
      @include line-clamp1;
      padding: 0.3rem 0.5rem;
      border-radius: 0.4rem;
      font-size: 1.6rem;
    }

    &-btn {
      background-color: $color-content-primary;
      color: #fff;
      border-radius: 0.4rem;
      width: 100%;
      border: none;
      margin-top: 2rem;
      &:hover {
        color: #fff !important;
        background-color: #403098 !important;
      }
    }
  }
}

@media screen and (min-width: 1650px) {
  .unConfirmed-card {
    width: calc(20% - 96px / 5);
  }
}

@media screen and (max-width: 1250px) {
  .unConfirmed-card {
    width: calc(100% / 3 - 48px / 3);
  }
}

@media screen and (max-width: 1030px) {
  .unConfirmed-card {
    width: calc(50% - 12px);
  }
}
@media screen and (max-width: 787px) {
  .unConfirmed-card {
    width: 100%;
  }
}

.unConfirmed-card-popover {
  width: 200px !important;
}
