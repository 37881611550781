@import "src/styles/variables.scss";

.pnl {
  .ant-statistic {
    height: 2rem !important;
  }
  .ant {
    &-table {
      td {
        padding: 5px 12px !important;
      }
      &-container {
        border-color: $color_input_border !important;
      }
      &-thead {
        tr {
          td,
          th {
            background-color: $color-pnl-header !important;
            border-color: $color_input_border !important;
          }
        }
      }
      &-tbody {
        tr {
          td {
            padding: 5px 12px !important;
            text-align: end;
            font-weight: 500;
            border-color: $color_input_border !important;

            &:nth-child(2) {
              text-align: start;
            }
          }
          &:first-child {
            td {
              padding: 0 !important;
            }
          }
        }
        tr {
          &[data-row-key*="green"] {
            background-color: $color-light-green !important;
            .ant-table-cell {
              &-fix-left {
                background-color: $color-light-green !important;
              }
              &-row-hover {
                background-color: $color-green !important;
              }
            }
          }
          &[data-row-key*="pink"] {
            background-color: $color-light-red !important;
            .ant-table-cell {
              &-fix-left {
                background-color: $color-light-red !important;
              }
              &-row-hover {
                background-color: $color-red !important;
              }
            }
          }
          &[data-row-key*="percentages"] {
            td {
              &:nth-child(2) {
                text-align: end;
                font-weight: 400;
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
}
