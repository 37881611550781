@import "src/styles/variables";

.transfer {
  &-switch {
    @include flex;
    gap: 1rem;
    p {
      font-size: 1.6rem;
      font-weight: $font-weight-secondary;
      font-family: $font-family-primary;
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    align-items: center;

    &-column {
      width: calc(50% - 24px - 24px);

      .ant-select {
        width: 25rem;
        margin-bottom: 1.6rem;
      }
    }
  }

  &-table {
    &-tag {
      @include flex;
      gap: 0.4rem;
      font-size: 1.6rem !important;
      color: $color-black;

      .ant-statistic,
      .ant-statistic-content {
        height: 2.4rem;
        font-size: 1.6rem !important;
      }
    }
  }

  .transfer-icon {
    flex-shrink: 0;
    width: 4.8rem;
    height: 4.8rem;
    margin-top: 4rem;
  }

  &-action {
    @include flex;
    gap: 1.6rem;
    margin-top: 3.6rem;

    &-input {
      width: 50rem;
    }
    &-btn {
      @include btn;
      background-color: $color-green;
      color: #fff;
      &:hover {
        background-color: $color-green-hover !important;
      }
    }

    .form-form {
      width: 100%;
      margin: 0;
      align-items: flex-end;

      .half {
        width: calc(50% - 2.8rem);
      }

      .form_item {
        width: 25rem;
      }
    }
  }
}

@media (min-width: 1700px) {
  .transfer-content-column {
    width: calc(100% / 3 - 36px);
  }
}

@media (max-width: 1173px) {
  .transfer {
    &-content {
      gap: 1.2rem;
      &-column {
        width: calc(50% - 12px - 16px);
      }
    }
    .transfer-icon {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

@media (max-width: 923px) {
  .transfer {
    &-content {
      flex-direction: column;
      align-items: flex-start;
      &-column {
        width: 100%;
      }
    }
    .transfer-icon {
      align-self: center;
      margin: 0;
    }
  }
}

@media (max-width: 600px) {
  .transfer {
    &-action {
      .form-form {
        .half {
          width: 100%;
        }
      }
    }
  }
}
