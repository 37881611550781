$color-white: var(--color_white);
$color-black: var(--color_black);
$color-primary: var(--color_primary);

$bgd-color-primary: var(--bgd_color_primary);

$color-header-border: var(--color_header_border);
$color-sidebar: var(--color_sidebar);
$bg-info: var(--bg_info);
$bg-color-header: var(--bg_color_header);
$color-input-border: var(--color_input_border);
$color-scroll: var(--color_scroll);
$color_scroll_dash: var(--color_scroll_dash);
$color_menu_icon: var(--color_menu_icon);
$color_gray_bg: var(--color_gray_bg);

$color-content-primary: var(--color_content_primary);
$color-content-tertiary: var(--color_content_tertiary);
$color-content-quaternary: var(--color_content_quaternary);

$color-green: var(--color_green);
$color-yellow: var(--color_yellow);
$color-red: var(--color_red);
$color-blue: var(--color_blue);

$color-light-green: var(--color_light_green);
$color-light-yellow: var(--color_light_yellow);
$color-light-red: var(--color_light_red);
$color-light-blue: var(--color_light_blue);
$color-table-blue: var(--color_table_blue);

$color-green-hover: var(--color_green_hover);
$color-grey-hover: var(--color_grey_hover);

$color-pnl-header: var(--color_pnl_header);
$color-pnl-green: var(--color_pnl_green);
$color-pnl-green-hover: var(--color_pnl_green_hover);
$color-pnl-pink: var(--color_pnl_pink);
$color-pnl-pink-hover: var(--color_pnl_pink_hover);

// TEXT Sizes AND Weights

$font-weight-primary: 700;
$font-weight-secondary: 600;
$font-weight-normal: 500;
$font-size-normal: 1.6rem;
$font-size-middle: 1.8rem;
$line-height-normal: 1.6rem;

// Font family
// $font-family-primary: "Montserrat", sans-serif;
// $font-family-secondary: "Roboto", sans-serif;
// $font-family-menu: "Open Sans", sans-serif;
$font-family-primary: "Poppins", sans-serif;
$font-family-secondary: "Poppins", sans-serif;
$font-family-menu: "Poppins", sans-serif;

// HEADER STYLES

$zIndex-sidebar-header: 1000;
$header-height: 7rem;
// $max-header-width: 1224px;

// Shadow
$shadow-card: 0 3px 3px rgba(56, 65, 74, 0.1);

// PADDINGS

$section-padding-primary: 12rem;
$section-padding-secondary: 8rem;

@mixin btn {
  line-height: 2rem;
  border-radius: 0.4rem;
  border: none;

  &:hover {
    color: #fff !important;
  }
}

.btn-primary {
  border: 1px solid $color-primary;
  color: $color-primary;
}

.btn-green {
  @include btn;
  background-color: $color-light-green;
  color: $color-green;
  &:hover {
    background-color: $color-green !important;
  }
}

.btn-yellow {
  @include btn;
  background-color: $color-light-yellow;
  color: $color-yellow;
  &:hover {
    background-color: $color-yellow !important;
  }
}
.btn-red {
  @include btn;
  background-color: $color-light-red;
  color: $color-red;
  &:hover {
    background-color: $color-red !important;
  }
}

// Display flex

@mixin flex {
  display: flex;
  align-items: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin card {
  padding: 1.6rem;
  border-radius: 0.4rem;
  box-shadow: $shadow-card;
  background-color: $color-white;
}
@mixin line-clamp1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
@mixin line-clamp2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

//Scroll
@mixin scroll {
  overflow-y: auto;
  /* width */
  margin-right: 0.3rem;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-content-tertiary;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-content-tertiary;
  }
}
@mixin scroll-menu {
  @include scroll;

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-sidebar;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-scroll;
  }
}
@mixin scroll-dashboard {
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $bgd-color-primary;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color_scroll_dash;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color_scroll_dash;
  }
}

@mixin scroll_10 {
  @include scroll-dashboard();
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
}

@mixin scroll-x {
  @include scroll-dashboard();
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px;
  }
}

.line-clamp2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
