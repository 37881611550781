@import "src/styles/variables";

.monitor {
  color: $color-black;

  &-header {
    @include flex-space-between;
    margin-bottom: 2rem;

    h2 {
      font-size: 2.4rem;
      font-weight: $font-weight-secondary;
      font-family: $font-family-primary;
    }

    &-right {
      @include flex;
      gap: 1rem;
      p {
        font-size: 1.6rem;
        font-weight: $font-weight-secondary;
        font-family: $font-family-primary;
      }
    }
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
  }

  &-card {
    @include card;
    width: calc(100% / 3 - 1.6rem);

    &-header {
      @include flex;
      gap: 1.6rem;
      margin-bottom: 1.6rem;

      & > div:first-child {
        width: 4.8rem;
        height: 4.8rem;
        @include flex-center;
        border-radius: 0.4rem;
        background-color: $color-content-quaternary;
        box-shadow: $shadow-card;
      }
      .dollor-icon {
        fill: $color-yellow;
      }
      .credit-icon {
        fill: $color-content-primary;
      }
      .pound-icon {
        font-size: 2.4rem;
        color: $color-red;
      }

      h4 {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 0.4rem;
        letter-spacing: 0.8px;
      }
      p {
        font-size: 1.6rem;
        letter-spacing: 0.5px;
        color: $color-content-tertiary;
      }
    }

    &-price {
      font-size: 2rem;
      font-weight: 500;
      color: $color-content-tertiary;
      margin-bottom: 1.6rem;
      height: 2.4rem;

      p {
        @include flex;
        gap: 0.3rem;
      }
      span {
        font-weight: 500;
        color: $color-content-tertiary;
      }
    }

    &-footer {
      padding-top: 1.6rem;
      border-top: 1px dashed $color_header_border;

      & > div {
        font-size: 1.4rem;
        color: $color-red;
        @include flex;
        gap: 0.4rem;
      }
    }
  }

  &-form {
    display: flex;
    align-items: flex-end;
    gap: 2.4rem;

    &-buttons {
      display: flex;
      align-items: flex-end;
      gap: 2.4rem;
    }
  }
}

@media screen and (max-width: 1000px) {
  .monitor-card {
    width: calc(50% - 1.2rem);
  }
}

@media screen and (max-width: 900px) {
  .monitor-card {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .monitor {
    .half {
      width: 100%;
    }
    &-form {
      flex-direction: column;
      gap: 0;
    }
  }
}
