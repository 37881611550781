@import "src/styles/variables";

.content {
  &-top {
    width: 100%;
    margin-bottom: 3.2rem;
    @include flex-space-between;
    color: $color-black;

    &-user {
      h3 {
        font-size: 1.8rem;
        line-height: 1.9rem;
        font-weight: $font-weight-normal;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-content-tertiary;
      }
    }

    &-children {
      @include flex;
      column-gap: 1.6rem;
    }

    &-btn {
      background-color: $color-light-green;
      color: $color-green;
      @include btn;

      &:hover {
        background-color: $color-green !important;
      }

      &-content {
        @include flex;
        column-gap: 0.4rem;
      }
    }

    .flex {
      @include flex;
      column-gap: 1.6rem;
    }

    // .add {
    //   margin-left: auto;
    // }

    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.routes {
  padding: 1rem 2.4rem;
  margin-bottom: 3.2rem;
  background-color: $color-white;
  color: $color-black;
}

@media (max-width: 753px) {
  .content-top {
    flex-direction: column;
    row-gap: 2rem;
    align-items: flex-start;
  }
}

@media (max-width: 455px) {
  .content-top {
    &-children {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1.6rem;
    }
  }
}
