@import "src/styles/variables.scss";

.auth {
  height: 100vh;
  background-image: url("../../assets//img/home/hero.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: $font-family-secondary;
  @include flex-center;
  position: relative;
  padding: 0 40px;

  &-logo {
    position: absolute;
    top: 20px;
    left: 40px;

    img {
      width: 140px;
      object-fit: cover;
    }
  }

  &-title {
    color: #131520;

    img {
      height: 80px;
      // width: 80px;
      object-fit: cover;
      margin-bottom: 16px;
    }
    h2 {
      line-height: 1.16;
      font-size: 48px;
      letter-spacing: -0.6px;
      margin-bottom: 16px;

      span {
        color: #7242f6;
      }
    }
    p {
      line-height: 34px;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .hidden {
    display: none;
  }

  &-form-container {
    max-width: 384px;
  }
  .signUp-width {
    max-width: 480px;
  }

  .form-form {
    column-gap: 14px;
    margin: 0;
  }
  .half {
    width: calc(50% - 7px);
  }
  &-form {
    &-header {
      margin-bottom: 24px;
      p {
        text-align: center;
        color: #03061a;
        margin-bottom: 24px;
        font-family: $font-family-secondary;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: -0.6px;
      }
      .signUp-title {
        font-size: 32px;
      }
    }

    .ant-input-affix-wrapper:hover {
      border: 1px solid #f8f9fb !important;
      input {
        background-color: #f8f9fb;
      }
    }
    .ant-input-affix-wrapper:active {
      border: 1px solid #b7afe1 !important;
    }
    .forgot-password {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: #4900b3 !important;
      }
    }

    &-input {
      border-radius: 8px;
      // border: 1px solid #e6e9f0;
      &:hover {
        border: 1px solid #f8f9fb !important;
        background-color: #f8f9fb !important;
      }
      &:active {
        border: 1px solid #b7afe1;
      }

      &-phone {
        .ant-input-wrapper {
          height: 40px;

          &:hover {
            border-color: #f8f9fb !important;
            input {
              background-color: #f8f9fb !important;
            }
          }
        }
        input {
          height: 40px;
        }
      }
    }

    &-footer {
      text-align: center;
      font-size: 1.6rem;
      color: $color-black;

      a {
        color: #4900b3;
        display: block;
      }
    }

    &-btn {
      width: 100%;
      border-radius: 12px;
      margin-bottom: 24px;
      font-weight: 500;
      line-height: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(0deg, #6936f5, #6936f5);

      &:hover {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 100%, rgba(255, 255, 255, 0) 0%),
          linear-gradient(0deg, #6936f5, #6936f5) !important;
      }
    }
  }
}
.auth-signUp {
  justify-content: space-between;
  .auth-title {
    flex: 1;
  }
  .auth-wrap {
    background-color: #fff;
    height: 100%;
    flex: 1.5;
    @include flex-center;
  }
}

@media (max-width: 1200px) {
  .auth-signUp {
    .auth-title {
      padding-right: 40px;
    }
    .auth-wrap {
      flex: 1;
    }
  }
}

@media (max-width: 950px) {
  .auth-title {
    display: none;
  }
}

@media (max-width: 600px) {
  .auth {
    padding: 0 20px;

    &-logo {
      left: 20px;

      img {
        width: 100px;
        object-fit: cover;
      }
    }

    &-form {
      &-header {
        margin-bottom: 24px;
        p {
          font-size: 28px;
          line-height: 32px;
        }
        .signUp-title {
          font-size: 24px;
        }
      }
    }
  }
}
