@import "src/styles/variables";

.sidebar {
  background-color: $color-sidebar;
  width: 25rem;
  height: 100vh;
  z-index: $zIndex-sidebar-header;
  border-right: 0.1rem solid $color-header-border;
  position: sticky;
  top: 0;

  &-header {
    @include flex;
    height: 7rem;
    overflow: hidden;
    padding: 0 2.4rem;

    &-img {
      width: 14rem;
      height: 3.6rem;
      background-image: url("../../assets/img/logo.png");
      background-repeat: no-repeat;
      background-position: 0;
      background-size: cover;

      &-dark {
        background-image: url("../../assets/img/logo-white.png");
      }
    }
  }

  &-circle {
    min-width: 0 !important;
    width: 3px;
    height: 3px;
    flex-shrink: 0 !important;
    border-radius: 50%;
    background-color: $color-black;
  }

  &-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 7rem);
    padding-top: 2rem;
    @include scroll-menu;

    &::-webkit-scrollbar {
      opacity: 0;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.8rem;
      color: $color-black;
      position: relative;
      font-family: $font-family-menu !important;

      &:hover {
        color: #6c5ffc !important;
      }

      // svg path {
      //   fill: $color_menu_icon;
      // }
    }
    .ant-menu-item {
      margin-block: 8px;
    }
    .ant-menu-submenu-title {
      margin-block: 12px;
    }

    .ant-menu-item:active {
      background-color: transparent !important;
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected {
      // svg path {
      //   fill: #6c5ffc;
      // }
      color: #6c5ffc;
    }
    .ant-menu-item-selected {
      background-color: $color-sidebar;
    }

    .ant-menu-sub .ant-menu-title-content {
      font-size: 1.4rem;
    }

    .ant-menu svg {
      width: 2.4rem;
      height: 2.4rem;
      flex-shrink: 0;
    }
    .ant-menu-sub svg {
      width: 1.3rem;
      height: 1.3rem;
    }
    .ant-menu-title-content {
      margin-inline-start: 0 !important;
    }
    // .arrowUp-icon {
    //   width: 14px !important;
    //   height: 14px !important;
    //   position: absolute;
    //   top: 50%;
    //   right: 6%;
    //   transform: translateY(-50%);
    //   transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    //     opacity 0.3s;
    // }
  }
  .scrolling-active {
    &::-webkit-scrollbar-thumb {
      background: $color-scroll;
      transition: background-color 0.5s ease;
    }
  }
}

.collapsed {
  width: 7rem;

  .sidebar-header {
    padding: 0 1.4rem;
  }
}

@media (max-width: 850px) {
  .sidebar-fixed {
    position: fixed !important;
    z-index: 1005;
  }
  .collapsed {
    display: none;
  }
}
