@import "src/styles/variables";

.chart-header {
  padding: 1.6rem;
  @include flex-space-between;
  gap: 1.6rem;
  flex-wrap: wrap;
  background-color: $color-white;
  border-bottom: 2px solid $color-content-quaternary;

  &-title {
    font-size: 2rem;
    font-weight: 500;
    flex-grow: 1;
  }

  &-filters {
    @include flex;
    column-gap: 24px;
    row-gap: 16px;
    flex-wrap: wrap;

    .filter {
      &-date {
        border: 1px solid #e0e4ea;
      }
    }

    .ant-radio-button-wrapper {
      height: 3.8rem;
      display: inline-flex;
      align-items: center;
    }
  }
  &-total {
    @include flex;

    gap: 1.5rem;

    .ant-statistic {
      height: 3rem !important;
      &-content {
        height: 3rem;
      }
    }

    h4 {
      font-size: 1.8rem;
      font-weight: 500;
    }
    h3 {
      span {
        font-size: 2rem;
        font-weight: 500;
        color: $color-content-tertiary;
      }
    }
  }
}

@media (max-width: 1033px) {
  .chart-header {
    &-total {
      order: 2;
    }
    &-filters {
      order: 3;
    }
  }
}
