@import "src/styles/variables";

.control_panel {
  width: 100%;
  // display: flex;
  // justify-content: space-between;

  &-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bgd_color_primary;
    padding: 6px 0 6px;
    z-index: 50;

    position: sticky;
    top: 7rem;
    margin-bottom: 18px;
  }

  &-content {
    & > div {
      padding-top: 2.4rem;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .control_panel {
    &-menu {
      top: 12.25rem;
    }
  }
}

@media screen and (max-width: 1050px) {
  .control_panel {
    &-menu {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 12px;
      & > div {
        &:first-child {
          width: 100%;
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .filter-date {
        align-self: flex-end;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .control_panel {
    &-menu {
      top: 11.26rem;
      .ant-anchor {
        font-size: 14px;
      }
      .ant-picker-input > input {
        font-size: 14px;
      }
    }
  }
}
