@import "src/styles/variables";

.expenses {
  color: $color-black;
  &-content {
    border-radius: 0.4rem;
    box-shadow: $shadow-card;
    overflow: hidden;
  }

  &-chart {
    color: $color-black;
    width: 100%;
    background-color: $color-white;
    padding: 1.6rem;
    min-height: calc(100vh - 17rem);

    &-cards {
      border-right: 2px solid $color_header_border;
      padding: 2.4rem 1.6rem;
    }
  }
}

@media (max-width: 1100px) {
  .expenses {
    &-chart {
      min-height: 500px;
    }
  }
}

@media (max-width: 1000px) {
}

@media (max-width: 570px) {
  .expenses {
    &-chart {
      min-height: 300px;
    }
  }
}
