@import "src/styles/variables";

.profit {
  color: $color-black;

  &-content {
    border-radius: 0.4rem;
    box-shadow: $shadow-card;
    overflow: hidden;
  }

  &-chart {
    border: none !important;
    border-radius: 0 !important;
  }
}

@media (max-width: 600px) {
  .profit {
    &-content {
      overflow-x: auto;
    }
    &-chart {
      width: 54.2rem !important;
    }

    &-chart-header {
      width: 54.2rem;
    }
  }
}
