@import "src/styles/variables.scss";

.dashboard {
  width: 100%;
  display: flex;
  align-items: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }

  /* Styling the horizontal scrollbar thumb (the draggable element) */
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &-collapsed {
    flex-grow: 1;
    width: calc(100% - 7rem);
  }
  &-right {
    flex-grow: 1;
    width: calc(100% - 25rem);
    transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  &-content {
    // @include scroll_10;
    width: 100%;
    min-height: calc(100vh - 7rem);
    padding: 2.4rem;
    background-color: $bgd-color-primary;

    &-nopadding {
      padding: 0;
    }
  }
}

@media (max-width: 1240px) {
  .dashboard {
    &-content {
      min-height: calc(100vh - 12.25rem);
    }
  }
}

@media (max-width: 600px) {
  .dashboard {
    &-content {
      padding: 1.6rem;
      min-height: calc(100vh - 11.26rem);
      &-mobile-full {
        padding: 1.6rem 0 1.6rem 1.6rem;
      }
      &-nopadding {
        padding: 0 !important;
      }
    }
  }
}
