@import "src/styles/variables";

.header {
  font-family: $font-family-primary;
  padding: 0 2.4rem;
  height: $header-height;
  z-index: $zIndex-sidebar-header;
  background-color: $color-sidebar;
  border-bottom: 0.1rem solid $color-header-border;
  color: $color-black;
  // box-shadow: $shadow-card;
  @include flex-space-between;
  position: sticky;
  top: 0;

  &-trigger {
    width: 40px;
    font-size: 24px;
    line-height: 64px;
    cursor: pointer;
    display: flex;
    align-items: center;

    path {
      transition: 0.3s !important;
      fill: $color-content-tertiary;
    }

    &:hover {
      path {
        fill: $color-content-primary !important;
      }
    }
  }

  &-left {
    @include flex;
    column-gap: 2rem;

    &-btn {
      border: none;
      box-shadow: none;
    }
  }

  &-buttons {
    @include flex;
    column-gap: 2rem;
    flex-grow: 1;

    .btn-primary {
      background-color: transparent !important;
    }
  }

  &-btn {
    &-green {
      @include flex;
      column-gap: 0.4rem;

      @include btn;
      background-color: $color-light-green;
      color: $color-green;
      &:hover {
        background-color: $color-green !important;
      }
    }
    &-red {
      @include flex;
      column-gap: 0.4rem;

      @include btn;
      background-color: $color-light-red;
      color: $color-red;
      &:hover {
        background-color: $color-red !important;
      }
    }
  }

  &-right {
    @include flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.6rem;

    &-screen-icon {
      font-size: 20px;
    }
  }

  &-profile {
    @include flex;
    gap: 1rem;
    background-color: $color_gray_bg;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    cursor: pointer;

    .user-icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      fill: $color-black;
    }

    &-info {
      p {
        font-size: 1.4rem;
        font-weight: $font-weight-normal;
      }
      span {
        font-size: 1.2rem;
        color: $color-content-tertiary;
        text-transform: capitalize;
      }
    }
  }
}
.user {
  & > div {
    @include flex;
    gap: 1rem;
    span {
      font-size: 1.4rem;
      color: $color-content-tertiary;
    }
  }
  .user-icon,
  .logout-icon {
    width: 1.8rem;
    height: 1.8rem;
    color: $color-content-tertiary;
    fill: $color-content-tertiary;
  }
}

@media (max-width: 1240px) {
  .header {
    flex-wrap: wrap;
    height: 12.25rem;
    padding: 1.2rem 2.4rem;
    &-right {
      order: 2;
    }
    &-buttons {
      width: 100%;
      order: 3;
      padding-top: 1rem;
      justify-content: center;
    }
  }
}

@media (max-width: 600px) {
  .header {
    padding: 1.6rem;
    height: 11.26rem;
    &-profile {
      background-color: transparent;
      padding: 0;
      &-info {
        display: none;
      }
    }
    &-right {
      gap: 1rem;
    }
  }
}

@media (max-width: 600px) {
  .header {
    &-buttons {
      column-gap: 1rem;
      button {
        padding: 3px 15px;
        font-size: 1.4rem;
        height: 3rem;
      }
    }
  }
}
