@import "src/styles/variables";

.dashboardPage {
  height: calc(100vh - 70px);
  padding: 20px;
  overflow-y: auto;
  background-image: url("../../assets/img/dashboard_bg_light.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  overflow-y: auto;
  @include scroll-10;
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.7);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  &-dark {
    background-image: url("../../assets/img/dashboard_bg.png");
  }

  &-filter {
    &-wrap {
      @include flex;
      gap: 16px;
      margin-top: 20px;
      justify-content: center;

      .ant-radio-group {
        // padding: 2px;
        // background-color: red;
        border-radius: 17px;
        border: 1px solid #fff;
      }

      .ant-radio-button-wrapper {
        background: transparent;
        border: none;
        color: #fff;
        font-weight: 400;
        &:hover {
          background-color: rgba(211, 222, 255, 0.2) !important;
        }
        &:first-child {
          border-top-left-radius: 17px;
          border-bottom-left-radius: 17px;
        }
        &:last-child {
          border-top-right-radius: 17px;
          border-bottom-right-radius: 17px;
        }
      }

      .ant-radio-button-wrapper-checked {
        background-color: rgba(211, 222, 255, 0.4);
        &::before {
          display: none !important;
        }
        &:hover {
          color: #fff !important;
          border-color: transparent !important;
        }
      }
      .ant-radio-button-wrapper::before {
        display: none !important;
      }
      .filter-date {
        background-color: transparent !important;
        border-radius: 17px;
        border: 1px solid #fff;
      }
      .ant-picker {
        background: transparent !important;
        border: none;

        &-outlined:focus-within,
        &-outlined:hover {
          border-color: #fff;
        }

        &-range-separator {
          span {
            color: #fff;
          }
        }
        &-suffix,
        &-clear {
          color: #fff;
        }

        &-input {
          &-placeholder {
            color: #fff;
            input {
              color: #fff;
            }
          }

          color: #fff;
          input {
            &::placeholder {
              color: #fff;
            }
          }
        }
      }
    }
  }

  &-statistics-cards {
    display: flex;
    // gap: 20px;
    margin-top: 60px;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 30px 40px;
    border-radius: 24px;
    overflow-x: scroll;
    @include scroll;
    height: 242px;

    & > div {
      &:first-child {
        border-right: 2px solid #dcd2d9;
        .statistics-card {
          &-top {
            p {
              font-weight: 600;
              // color: $color-black;
            }
          }
          &-body {
            width: 200px;
          }
        }
      }
      &:not(&:first-child) {
        padding-left: 15px;
      }
      &:not(&:last-child) {
        padding-right: 15px;
      }
    }

    &-wrap {
      border-radius: 24px;
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.7);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.7);
    }

    .statistics-card {
      width: calc();
      padding-top: 10px;
      padding-bottom: 10px;
      &-top {
        text-align: center;
        position: relative;
        border-bottom: 3px solid #99ccff;
        padding-bottom: 8px;
        margin-bottom: 16px;
        p {
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 6px;
        }
        span {
          font-size: 14px;
          line-height: 14px;
        }
      }
      &-body {
        width: 180px;
        border: 1px solid #fff;
        border-radius: 10px;
        text-align: center;
        padding: 16px 0;

        span {
          font-weight: 600;
          font-size: 32px;
          letter-spacing: -0.04em;
          text-transform: uppercase;
        }
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 40px;
  }

  .dashboard-card {
    height: 280px;
    background-color: rgba(12, 37, 61, 0.85);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    overflow: hidden;

    &-title {
      @include flex-space-between;
      padding: 8px 20px;
      font-size: 16px;
      line-height: 24px;
      border-bottom: 1px solid #fff;
      column-gap: 10px;

      .ant-select {
        width: 200px;
        &-selection {
          &-item {
            color: white !important;
          }
        }
        &-selector {
          background-color: transparent !important;

          input {
            &::placeholder {
              color: #fff !important;
            }
          }
        }
      }
    }
    &-left-title {
      font-size: 16px;
      line-height: 24px;
      border-right: 1px solid #fff;
    }
    &-right {
      width: 60%;
      overflow: hidden;

      &-top {
        @include flex;
        gap: 4px;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;

        div {
          height: 24px;
          background-color: #fff;
          width: 1px !important;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          flex-grow: 1;
        }
      }
    }
  }

  .income-card {
    &-body {
      display: flex;
      padding: 16px 14px;
      height: 239px;
    }

    &-left {
      width: 40%;

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        height: 100%;
      }

      h4 {
        font-size: 34px;
        line-height: 36px;
        color: #8275ff;
        font-weight: 600;
        // margin-bottom: 16px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .profit-card {
    &-body {
      display: flex;
      padding: 16px 14px;
      height: 239px;
    }
    .echarts-for-react {
      height: 27px;
    }

    &-left {
      &-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: calc(100% - 24px);
        gap: 40px;

        h4 {
          font-size: 18px;
          line-height: 36px;
        }

        div {
          p {
            font-size: 16px;
            margin-bottom: 6px;
          }
          span {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
      &-green {
        color: #00d669;
      }
      &-red {
        color: #ff7779;
      }
    }
  }
  .cash-card {
    &-body {
      @include flex-center;
      height: 239px;

      div {
        text-align: center;
      }

      span {
        font-size: 34px;
        line-height: 46px;
        color: #00d669;
      }
      p {
        margin-top: 8px;
        font-size: 15px;
      }
    }
  }
  .remains-card {
    &-body {
      @include flex-center;
      height: 239px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;

      div {
        display: flex;
        align-items: flex-end;
        gap: 14px;
        span {
          font-size: 34px;
        }
        p {
          font-size: 16px;
        }
      }

      div:first-child > span {
        color: #00d669;
      }
      div:last-child > span {
        color: #ff7779;
      }
      p {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .dashboard-expenses-card {
    grid-column: span 2;
    &-body {
      height: 239px;
      display: flex;
      justify-content: space-between;
    }
    &-chart {
      width: 50%;
    }
  }
  .dashboard-payment-card {
    grid-column: span 2;
    &-body {
      height: 239px;
    }
  }
  .payment-card {
    height: auto;

    .ant-descriptions-row,
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      border-color: rgb(126, 126, 126) !important;
    }
    span {
      font-size: 16px;
      color: inherit;
    }
    .ant-descriptions-item-label span {
      color: white;
    }
    .ant-descriptions-item-content {
      color: #dcd2d9;
    }
    .ant-spin-nested-loading {
      width: 100%;
      height: 100%;
      .ant-spin-container {
        height: 100%;
      }
    }
    .cash-card-body {
      height: 300px;
    }
  }
  .payment-chart {
    height: auto;
    grid-column: span 2;
  }
  .customTable {
    padding: 16px;
    height: 100%;
    & > div {
      flex-grow: 1;
    }
  }
  .ant-flex {
    h2 {
      width: 80px;
      flex-shrink: 0;
      text-align: left;
    }
    p {
      width: 80px;
      flex-grow: 1;
      margin: 0;
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 1300px) {
  .dashboardPage-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 660px) {
  .dashboardPage-filter-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
  .dashboard-card {
    grid-column: span 2;
  }
}

@media screen and (max-width: 570px) {
  .dashboardPage {
    &-filter {
      &-wrap {
        margin-top: 0;
        .filter-date {
          .ant-picker .ant-picker-input > input {
            font-size: 1.4rem !important;
          }
        }
      }
      .ant-radio-button-wrapper {
        height: 3.2rem !important;
        line-height: 3.2rem !important;
        font-size: 1.4rem !important;
        padding-inline: 1rem !important;
      }
    }
    &-statistics {
      &-cards {
        height: auto;
        margin-top: 30px;
        padding: 20px;

        .statistics-card {
          &-top {
            p {
              font-size: 1.4rem;
              margin-bottom: 0;
            }
          }
          &-body {
            width: 150px;

            span {
              font-size: 24px;
            }
          }
        }
      }
    }
    &-cards {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }

    .dashboard-card {
      &-title {
        font-size: 14px;
        .ant-select {
          width: 140px !important;
        }
      }
    }
  }
}
