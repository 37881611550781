@import "src/styles/variables.scss";

.team {
  &-cards {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
  }

  &-add {
    &-upload {
      width: 100%;
      text-align: center;
      min-height: 8.8rem;
    }
  }
}

.phoneInput {
  .ant-input-group-addon {
    font-size: 1.6rem;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    color: $color-black;
    background-color: $bg-color-header;
    border-color: $color-input-border;
  }

  #normal_login_phone {
    font-size: 1.6rem;
    height: 3.8rem;
    color: $color-black;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    background-color: $bg-color-header;
    border-color: $color-input-border;

    &:hover {
      border-color: #b7afe1 !important;
    }
  }
}

@media (max-width: 1200px) {
  .team {
    &-cards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 2.4rem;
    }
  }
}
