@import "src/styles/variables.scss";

.modal {
  overflow: hidden;
  color: $color-black !important;

  &-header {
    margin-bottom: 3rem;
    padding: 1.6rem 2rem;
    background-color: $bg-info;
    overflow: hidden;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    position: relative;

    h2 {
      font-size: 1.8rem;
      font-weight: $font-weight-primary;
      margin-bottom: 0.2rem;
      color: $color-black;
    }
    p {
      font-size: 1.6rem;
      color: $color-content-tertiary;
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
    padding-top: 3rem;
    margin-bottom: 2rem;
  }

  &-add-btn,
  &-cancel-btn {
    border: none;
    box-shadow: $shadow-card;
    font-size: 1.6rem;
  }
  &-add-btn {
    background-color: $color-green;
    &:hover {
      background-color: $color-green-hover !important;
    }
  }
  &-cancel-btn {
    background-color: $color-content-quaternary;
    &:hover {
      background-color: $color-grey-hover !important;
      color: $color-black !important;
    }
  }
}

.ant-modal-close {
  position: absolute !important;
  top: 3% !important;
  right: 6% !important;
}
.ant-form-item-required {
  // display: none !important;
}
