@import "src/styles/variables";

.select-language {
  &-wrap {
    @include flex;
    gap: 6px;
    position: relative;
    z-index: 5;
    cursor: pointer;

    div {
      @include flex;
      gap: 8px;
      .language-icon {
        width: 20px;
        height: 20px;
        fill: $color-black;
      }
      font-size: 14px;
    }
    .arrow-down-icon {
      width: 14px;
      height: 14px;
      fill: $color-black;
    }
  }
}

// @media (max-width: 750px) {
//   .select-language {
//     display: none;
//   }
//   .visible {
//     display: block;
//     position: relative;
//     z-index: 10;
//   }
// }
