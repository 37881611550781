@import "src/styles/variables";

.section-priority {
  padding: 10rem 0;
  background-color: #f4f8fc;

  .priority {
    &-wrap {
      position: relative;
      z-index: 5;

      h2 {
        letter-spacing: -0.01em;
        font-size: 48px;
        font-weight: 600;
        line-height: 72px;
        max-width: 770px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 20px;

        span {
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
          -webkit-background-clip: text;
        }
      }
    }
    &-subtitle {
      letter-spacing: -0.02em;
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 80px;
      max-width: 500px;
    }
    &-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr auto;
      gap: 16px;

      h3 {
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
        -webkit-background-clip: text;
        letter-spacing: -0.01em;
        font-size: 48px;
        font-weight: 500;
        line-height: 72px;
        text-align: center;
      }
    }
    &-header-card {
      background-color: #fff;
      padding: 40px;
      border-radius: 12px;
    }
    &-main-card {
      padding: 24px 32px;
      background-color: #fff;
      border-radius: 12px;

      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      li {
        letter-spacing: -0.02em;
        font-size: 18px;
        line-height: 32px;
        margin-left: 10px;
        // color: #565656;
      }
    }
  }
  .aqcham-card {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    background-image: linear-gradient(
        rgba(64, 50, 157, 0.3),
        rgba(196, 50, 221, 0.4)
      ),
      url("../../../../assets/img/home/priority-bg.png");
  }
}

@media (max-width: 850px) {
  .section-priority {
    .priority {
      &-cards {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto 1fr auto 1fr;
        gap: 16px;
      }
    }
    .card-1 {
      order: 1;
    }
    .card-2 {
      order: 2;
    }
    .card-3 {
      order: 5;
    }
    .card-4 {
      order: 3;
    }
    .card-5 {
      order: 4;
    }
    .card-6 {
      order: 6;
      grid-row: 4 5;
      grid-column: -3;
      grid-row: 4;
    }
  }
}
@media (max-width: 620px) {
  .section-priority {
    padding: 80px 0;
    .priority {
      &-wrap {
        h2 {
          line-height: 32px;
          font-size: 28px;
          max-width: 100%;
        }
      }
      &-subtitle {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 60px;
        max-width: 100%;
      }
      &-cards {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto 1fr auto auto;
        gap: 16px;

        h3 {
          font-size: 28px;
          line-height: 32px;
        }
      }
      &-header-card {
        padding: 20px;
      }
      &-main-card {
        padding: 12px 20px;
        li {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .section-priority {
    padding: 80px 0;
    .priority {
      &-cards {
        display: flex;
        flex-direction: column;
      }
    }
    .card-1 {
      order: 1;
    }
    .card-2 {
      order: 3;
    }
    .card-3 {
      order: 5;
    }
    .card-4 {
      order: 2;
    }
    .card-5 {
      order: 4;
    }
    .card-6 {
      order: 6;
    }
  }
}
