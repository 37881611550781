@import "src/styles/variables.scss";

.journal {
  .ant-table-thead {
    .ant-table-column-title {
      @include line-clamp1;
    }
  }
  &-excel {
    border: 1px solid $color-primary !important;
  }
  &-button {
    background-color: transparent;
  }
  &-table {
    color: $color-black;
    border-radius: 0px;

    .ant-table-container {
      border-radius: 0;
    }

    &-wrap {
      padding: 24px;
      background-color: $color-white;
      border-radius: 7px;
    }
  }
  &-actions {
    @include flex;
    gap: 2rem;
    margin-bottom: 1.6rem;
  }
  &-filters {
    padding: 16px 24px;
    background-color: var(--color_white);
    border-bottom: 2px solid var(--color_content_quaternary);
    border-radius: 0.4rem;
    box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
    margin-bottom: 16px;

    .form-form {
      margin: 0;
    }
    .w-100 {
      width: 100px;
    }
    .w-150 {
      width: 150px;
    }
    .w-200 {
      width: 200px;
      // color: #e0e4ea;
    }
    .w-250 {
      width: 250px;
    }
    .w-300 {
      width: 300px;
    }
    .w-400 {
      width: 400px;
    }
  }
  .income-column {
    .ant-statistic,
    .ant-statistic-content {
      color: $color-green;
    }
  }
  .outcome-column {
    .ant-statistic,
    .ant-statistic-content {
      color: $color-red;
    }
  }
  .income-column,
  .outcome-column {
    display: flex;
    justify-content: center;

    .ant-statistic,
    .ant-statistic-content {
      font-size: 1.4rem !important;
    }
  }
  .income-column-icon {
    background-color: $color-light-green;
  }
  .outcome-column-icon {
    background-color: $color-light-red;
  }
}
