@import "./variables.scss";

.ant-upload-list-item-container {
  & > div {
    &:nth-child(2) {
      display: none;
    }
  }
}
.ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
  border-color: #d9d9d9 !important;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 8.8rem !important;
  height: 8.8rem !important;
  margin: 0;

  svg {
    width: 4rem;
    height: 4rem;
  }
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-container {
  width: 8.8rem !important;
  height: 8.8rem !important;
  margin: 0;
}
.img-crop-modal .ant-modal-content {
  padding: 2rem !important;
}
.ant-form-item {
  margin-bottom: 2rem;
  &-label {
    padding: 0 0 0.4rem !important;
  }
}
.ant-form-item-control {
  & > div {
    &:nth-child(2) {
      height: 2rem;
    }
  }
}
.ant-form-item .ant-form-item-explain {
  font-size: 1.4rem !important;
}
.ant-popover {
  width: 30rem !important;
}
.ant-select .ant-select-arrow {
  color: $color-content-tertiary;
}
.ant-spin-container,
.ant-table-scroll-horizontal {
  /* Track */
  &::-webkit-scrollbar-track {
    background: $bgd-color-primary !important;
  }
}
.cashflow_table {
  .ant-table-thead .ant-table-cell {
    background-color: $color-table-blue !important;
  }
  .ant-table-tbody tr .ant-table-cell {
    &:first-child,
    &:nth-child(2) {
      background-color: $color-table-blue !important;
    }
  }
}
.ant-pagination {
  .ant-select-selector {
    height: 32px !important;
    display: flex;
    align-items: center;
  }
}
.ant-statistic,
.ant-statistic-content {
  height: 2rem;
  font-size: 2rem !important;
}

.table-statistic {
  .ant-statistic,
  .ant-statistic-content {
    font-size: 1.4rem !important;
    height: 2rem !important;
  }
  &-income {
    .ant-statistic,
    .ant-statistic-content {
      color: $color-green;
      font-size: 1.4rem !important;
      height: 2rem !important;
    }
  }
  &-outcome {
    .ant-statistic,
    .ant-statistic-content {
      color: $color-red;
      font-size: 1.4rem !important;
      height: 2rem !important;
    }
  }
}

.customCheckbox {
  padding: 6px 12px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);

  &__transparent {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 24px;
  }
}
