@import "src/styles/variables.scss";

.action {
  width: 6.6rem;
  @include flex;
  justify-content: center;
  column-gap: 1rem;
}
.action-btn-yellow,
.action-btn-red {
  box-shadow: none;
  @include btn;
  width: 3rem;
  height: 2.4rem;
  @include flex-center;
}

.action-btn-yellow {
  color: $color-yellow;
  background-color: $color-white;

  &:hover {
    background-color: $color-yellow !important;
    color: $color-white !important;
  }
}

.action-btn-red {
  color: $color-red;
  background-color: $color-white;

  &:hover {
    background-color: $color-red !important;
    color: $color-white !important;
  }
}
