@import "src/styles/variables";

.footer {
  padding-top: 8rem;
  background-color: #fff;
  position: relative;
  z-index: 5;
  color: #262b40;
  position: relative;

  &-wrap {
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
    gap: 6.4rem;

    p,
    a {
      font-size: 1.6rem;
      line-height: 140%;
    }
    a {
      color: #262b40;
    }
  }

  &-info {
    img {
      width: 19rem;
      object-fit: cover;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &-medias {
    margin-top: 2.4rem;
    @include flex;
    gap: 3.2rem;

    div {
      width: 40px;
      height: 40px;
      @include flex-center;
      background-color: #f5f2ff;
      border-radius: 50%;
      cursor: pointer;
    }

    .telegram-icon,
    .facebook-icon,
    .instagram-icon {
      width: 24px;
      height: 24px;
      fill: #4900b3;
    }
  }

  &-title {
    font-size: 1.8rem !important;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &-columns {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .button-filled {
      margin-top: 2.4rem;
      &:hover {
        background-color: #5e00e6 !important;
      }
    }
  }

  &-copyright {
    margin: 5rem 0 5rem 0;
    text-align: end;
    font-size: 1.4rem;
  }
}

@media (max-width: 950px) {
  .footer {
    &-wrap {
      gap: 2.4rem;
    }
  }
}
@media (max-width: 820px) {
  .footer {
    &-wrap {
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 40px;
    }
    &-info {
      grid-column: span 3;
    }
  }
}
@media (max-width: 520px) {
  .footer {
    &-wrap {
      grid-template-columns: 1fr 1fr;
    }
    &-info {
      grid-column: span 2;
    }
  }
}
