@import "src/styles/variables";

.portion {
  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    margin-bottom: 2.4rem;
  }
}

@media (max-width: 670px) {
  .portion {
    &-content {
      overflow-x: auto;
      overflow-y: hidden;
    }
    &-chart {
      width: 61.1rem !important;
    }
  }
}
