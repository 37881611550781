@import "src/styles/variables";

.section-team {
  padding: 10rem 0;
  position: relative;
  z-index: 5;
  background-color: #fff;

  .team {
    &-wrap {
      h2 {
        letter-spacing: -0.01em;
        font-size: 48px;
        font-weight: 500;
        line-height: 72px;
        text-align: center;
        margin-bottom: 80px;
      }
      &-members {
        position: relative;

        .slider-prev,
        .slider-next {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #6800ff;
          @include flex-center;
          color: #fff;
          position: absolute;
          z-index: 3;

          &:hover {
            background-color: #771aff !important;
          }

          svg {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
          }
        }
        .slider-prev {
          top: 39%;
          left: -5%;
        }
        .slider-next {
          top: 39%;
          right: -5%;
        }
      }
    }
    &-experience {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 40px;

      div {
        width: calc(25% - 72px / 4);
        background-color: #f5f2ff;
        padding: 20px;
        border-radius: 12px;

        span {
          letter-spacing: -0.01em;
          font-size: 48px;
          font-weight: 500;
          line-height: 72px;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
          background-clip: text;
        }
        p {
          margin-top: 20px;
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
    &-slide-cards {
      .swiper-pagination-bullet-active {
        background: #6800ff;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          border: 1px solid #6800ff;
          width: 12px;
          height: 12px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          z-index: 1;
        }
      }
    }
    &-slide-card {
      padding: 16px;
      border-radius: 12px;
      background-color: #f4f8fc;
      height: 390px;
      display: grid;
      flex-direction: column;
      margin-bottom: 40px;
      max-width: 264px;

      &-img {
        height: 270px;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 16px;

        img {
          // height: 100%;
          width: 100%;
          object-fit: cover;
          position: relative;
          top: -20px;
        }
      }
      &-info {
        h4 {
          // letter-spacing: -0.02em;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
          // line-height: 40px;
        }
        p {
          font-size: 16px;
          color: #555;
        }
      }
    }
  }
}

@media (max-width: 782px) {
  .section-team {
    .team {
      &-experience {
        div {
          width: calc(100% / 3 - 16px);
          background-color: #f5f2ff;
          padding: 20px;
          border-radius: 12px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .section-team {
    padding: 80px 0;
    .team {
      &-wrap {
        h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 60px;
        }
      }
      &-experience {
        div {
          width: calc(50% - 12px);
          padding: 16px;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .section-team {
    padding: 80px 0;
    .team {
      &-experience {
        div {
          width: calc(50% - 12px);
          padding: 16px;

          span {
            font-size: 28px;
            line-height: 32px;
          }
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
