@import "src/styles/variables.scss";

.debt_credit {
  &-table {
    padding: 16px;
    background-color: var(--color_white);
    border-radius: 7px;
    margin-bottom: 24px;

    &-title {
      span {
        opacity: 0.8;
        font-style: italic;
      }
    }

    .add {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      .content-top-btn-content {
        font-size: 14px;
      }
    }

    .ant-table {
      td,
      th {
        padding: 6px !important;
        min-width: 100px;
        font-size: 13px;
      }

      tr {
        &[aria-hidden="true"] {
          td {
            padding: 0 !important;
          }
        }
        th[rowspan="2"] {
          &:first-child {
            min-width: 220px;
          }
        }
      }

      &-thead {
        tr:first-child {
          th[scope="colgroup"] {
            background-color: $color-pnl-header;
          }
        }
      }
    }

    &-customer {
      .ant-table {
        &-thead {
          tr:first-child {
            th:first-child {
              background-color: $color-pnl-green;
            }
          }
        }
      }
    }
    &-supplier {
      .ant-table {
        &-thead {
          tr:first-child {
            th:first-child {
              background-color: $color-pnl-pink;
            }
          }
        }
      }
    }
    &-rent {
      .ant-table {
        &-thead {
          tr:first-child {
            th:first-child {
              background-color: $color_gray_bg;
            }
          }
        }
      }
    }
    &-employee {
      .ant-table {
        &-thead {
          tr:first-child {
            th:first-child {
              background-color: $color_scroll_dash;
            }
          }
        }
      }
    }
  }
  &-form {
    .ant-form-item-label label {
      @include line-clamp1;
    }
  }
}
