@import "src/styles/variables";

.home-header {
  padding: 24px 0;

  &-wrap {
    @include flex-space-between;
  }

  &-logo {
    img {
      height: 4rem;
      object-fit: cover;
    }
  }

  &-menu {
    @include flex;
    gap: 1.6rem;
    // display: none;

    &-items {
      padding: 0.4rem 0.8rem;
      border-radius: 0.8rem;

      &:hover {
        background-color: $color-content-quaternary !important;
      }
    }

    a {
      color: $color-black;
      font-family: $font-family-secondary;
      font-size: 1.6rem;
    }
  }

  &-nav {
    display: none;
    border: none;
    background: none;
    cursor: pointer;
  }

  &-buttons {
    @include flex;
    gap: 1rem;

    .signIn-buttons {
      display: flex;
      gap: 12px;
    }
  }

  .close-icon {
    width: 2.4rem;
    height: 2.4rem;
  }
}

@media (max-width: 1250px) {
  .home-header {
    &-menu {
      position: fixed;
      background: white;
      top: 0;
      left: -100%;
      height: 100vh;
      width: 100vw;
      z-index: 100;
      flex-direction: column;
      gap: 65px;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease;

      &-active {
        left: 0;
      }
    }
    &-language {
      z-index: 102;
    }

    .hidden {
      display: none;
    }

    .shown {
      display: block;
    }

    &-nav {
      z-index: 101;
      display: block;
    }
    .signIn-buttons {
      margin-right: 24px;
    }
  }
}

@media (max-width: 600px) {
  .home-header {
    &-logo {
      height: 4rem;
      width: 16rem;
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .signIn-buttons {
      display: none;
    }
  }
}
@media (max-width: 500px) {
  .home-header {
    padding: 16px 0;
    &-logo {
      height: 4rem;
      width: 16rem;
      img {
        height: 74%;
        width: 74%;
        object-fit: cover;
      }
    }

    .signIn-buttons {
      display: none;
    }
  }
}
