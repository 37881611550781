@import "./variables.scss";
@import "./help.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // list-style-type: none;
  font-family: "Poppins", sans-serif;
}

html,
body {
  font-family: $font-family-secondary;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

html {
  /* font-size: 10px; */
  /* We want font-size 10 px 10 / 16 => 0.625 => 62.5 % */
  /* Persentage of user's browser font-size setting */
  font-size: 62.5%; // 1rem => 10px
}

.root {
  min-height: 100vh;

  // overflow-x: hidden;
}

.container {
  max-width: 1200px;
  padding-right: 40px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.input {
  outline: none !important;
}

.form {
  width: 100%;
  padding: 0 2rem 0.1rem;
  &-form {
    margin: 0 2rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
  }
}
.full {
  width: 100%;
}

.half {
  width: calc(50% - 1rem);
}
.third {
  width: calc(100% / 3 - 4rem / 3);
}

.statistics-chart {
  background-color: $color-white;
  width: 100%;
  height: 550px;
  // padding-top: 2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $color_header_border;
}
