@import "src/styles/variables";

.section-contact {
  padding: 10rem 0;
  background-color: #fff;
  position: relative;
  z-index: 5;

  .contact {
    &-input {
      &-phone {
        .ant-input-wrapper {
          height: 40px;

          input {
            height: 40px;

            &:hover,
            &:focus {
              border-color: $color-primary;
            }
          }
        }
        height: 40px;
      }
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
    &-details {
      h2 {
        letter-spacing: -0.01em;
        font-size: 56px;
        font-weight: 500;
        line-height: 72px;
        max-width: 560px;
        margin-bottom: 40px;

        span {
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
          -webkit-background-clip: text;
        }
      }
      &-connect {
        @include flex;
        gap: 32px;

        div {
          @include flex;
          gap: 10px;
          letter-spacing: -0.02em;
          font-size: 18px;

          a {
            color: $color-black;

            &:hover {
              color: $color-primary !important;
            }
          }
        }
        .phone-icon,
        .telegram-icon {
          width: 24px;
          height: 24px;
          fill: #6800ff;
        }
      }
    }
    &-form {
      max-width: 480px;
      padding: 28px 20px;
      background-color: #f4f8fc;
      border-radius: 12px;

      p {
        letter-spacing: -0.02em;
        font-family: Aeonik Pro, sans-serif;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        padding: 0 18px;
        margin-bottom: 32px;
      }
      .button-filled {
        width: 100%;
        margin-top: 20px;
        &:hover {
          background-color: #5e00e6 !important;
        }
      }
    }
  }
}

@media (max-width: 1020px) {
  .section-contact {
    .contact {
      &-wrap {
        flex-direction: column;
        gap: 60px;
      }
      &-form {
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 600px) {
  .section-contact {
    padding: 80px 0;
    .contact {
      &-details {
        h2 {
          font-size: 28px;
          line-height: 32px;
          max-width: 100%;
          text-align: center;
        }
      }
      &-form {
        align-self: flex-start;
        p {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .section-contact {
    .contact {
      &-details {
        &-connect {
          flex-direction: column;
          gap: 16px;
        }
      }
      &-form {
        padding: 16px;
        p {
          font-size: 20px;
          line-height: 26px;
        }
        .button-filled {
          padding: 0;
          &:hover {
            background-color: #5e00e6 !important;
          }
        }
      }
    }
  }
}
