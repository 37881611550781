@import "src/styles/variables";

.pending {
  color: $color-black;

  h2 {
    font-family: $font-family-primary;
    font-size: 2.4rem;
    font-weight: $font-weight-secondary;
    margin-bottom: 3.2rem;
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
  }
}
