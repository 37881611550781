@import "src/styles/variables";

.section-about {
  padding: 0 0 10rem 0;
  background-color: #f4f8fc;

  .about {
    &-wrap {
      position: relative;
      z-index: 3;

      h2 {
        letter-spacing: -0.01em;
        font-size: 48px;
        font-weight: 500;
        line-height: 72px;
        max-width: 560px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 80px;

        span {
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
          -webkit-background-clip: text;
        }
      }

      &-header {
        width: 440px;
        height: 312px;
        margin: 0 auto;
        position: relative;
        background-image: url("../../../../assets/img/home//about-bg.png");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        @include flex-center;

        .coin {
          width: 80px;
          height: 80px;
          position: absolute;
        }
        .coin-1 {
          top: 39%;
          left: -6%;
        }
        .coin-2 {
          top: 2%;
          left: 14%;
        }
        .coin-3 {
          top: 2%;
          right: 14%;
        }
        .coin-4 {
          top: 39%;
          right: -6%;
        }

        &-img {
          width: 90px;
          height: 90px;
        }

        .moneyBg-icon {
          width: 90px;
          height: 90px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          z-index: 0;
        }
        img {
          width: 100%;
          position: relative;
          z-index: 3;
        }
      }
    }
    &-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 16px;
    }
    &-card {
      padding: 40px;
      background-color: #fff;
      border-radius: 12px;
      position: relative;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-10px);
      }

      &-top {
        margin-bottom: 32px;
        .about-icon {
          width: 48px;
          height: 48px;
          fill: #9b84fa;
        }
      }
      &-offset-line {
        height: 18px;
        border: 1px solid #d9dde8;
        border-top-style: none;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        position: absolute;
        top: auto;
        bottom: 0;
        left: 16px;
        right: 16px;
        transform: translateY(100%);
      }
      h4 {
        letter-spacing: -0.02em;
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
}

@media (max-width: 968px) {
  .section-about {
    .about {
      &-cards {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
      }
    }
  }
}
@media (max-width: 670px) {
  .section-about {
    padding-bottom: 80px;
    .about {
      &-wrap {
        h2 {
          max-width: 463px;
          line-height: 32px;
          font-size: 28px;
        }
        &-header {
          width: 330px;
          height: 310px;

          .moneyBg-icon {
            width: 80px;
            height: 80px;
          }

          .coin {
            width: 60px;
            height: 60px;
            position: absolute;
          }
          &-img {
            width: 80px;
            height: 80px;
          }
        }
      }
      &-card {
        padding: 20px;
        h4 {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}
@media (max-width: 460px) {
  .section-about {
    .about {
      &-wrap {
        h2 {
          margin-bottom: 60px;
        }
        &-header {
          width: 234px;
          height: 270px;

          .moneyBg-icon {
            width: 60px;
            height: 60px;
          }

          .coin {
            width: 54px;
            height: 54px;
            position: absolute;
          }
          .coin-2 {
            top: 10%;
            left: 14%;
          }
          .coin-3 {
            top: 10%;
            right: 14%;
          }
          &-img {
            width: 60px;
            height: 60px;
          }
        }
      }
      &-card {
        padding: 12px;
        h4 {
          font-size: 16px;
          line-height: 24px;
        }
        &-top {
          margin-bottom: 24px;
          .about-icon {
            width: 36px;
            height: 36px;
            fill: #9b84fa;
          }
        }
        &-offset-line {
          height: 10px;
          left: 10px;
          right: 10px;
        }
      }
    }
  }
}
