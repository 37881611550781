@import "src/styles/variables";

.section-hero {
  padding: 13rem 0 13rem 0;
  color: #060a23;
  position: relative;
  z-index: 3;
  background-image: url("../../../../assets/img/home/hero.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .hero {
    &-wrap {
      @include flex-center;
      flex-direction: column;
      gap: 2.4rem;

      h1,
      h4 {
        // max-width: 60%;
        text-align: center;

        span {
          display: block;
        }
      }

      h1 {
        font-size: 54px;
        line-height: 72px;
        font-weight: 500;
        letter-spacing: -0.02em;

        span {
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(#9076fc 16%, #a996f8 95%);
          -webkit-background-clip: text;
        }
      }
      h4 {
        letter-spacing: -0.02em;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 2.4rem;
        font-weight: 400;
      }
    }
    &-buttons {
      @include flex;
      gap: 1.2rem;
      margin-bottom: 2.4rem;

      .filled,
      .outlined {
        padding: 12px 24px !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .section-hero {
    .hero {
      &-wrap {
        h1 {
          max-width: 580px;
        }
        h4 {
          max-width: 500px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .section-hero {
    .hero {
      &-wrap {
        h1 {
          width: 100%;
          font-size: 40px;
          line-height: 58px;
        }
        h4 {
          max-width: 500px;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .section-hero {
    padding: 80px 0;
    .hero {
      &-wrap {
        h1 {
          font-size: 28px;
          line-height: 36px;
        }
        h4 {
          width: 100%;
          font-size: 14px;
          line-height: 24px;
        }
      }
      &-buttons {
        flex-direction: column;
        gap: 24px;
        .filled,
        .outlined {
          padding: 8px 12px !important;
        }
      }
    }
  }
}
