@import "src/styles/variables";

.resources {
  a {
    color: #262b40;
  }

  .explain-cards {
    // margin: 0 0px;
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    grid-template-columns: 1fr 1fr 1fr;
    .img-card-wrap {
      height: 200px;

      img {
        object-fit: fill;
      }
    }

    h4 {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 12px !important;
    }
  }
}

@media (max-width: 848px) {
  .resources {
    .explain {
      &-cards {
        grid-template-columns: 1fr;
        margin-bottom: 60px;

        .img-card,
        .text-card {
          max-width: 500px !important;
        }
      }
    }
  }
}
